import React from "react";
import { Box, Flex, Image, Heading, FlexProps } from "@chakra-ui/react";
import getConfig from "next/config";
import checkIfFullFridge from "@/utils/fullFridge";

const { publicRuntimeConfig } = getConfig();

type Props = {
  title?: string;
  description?: string;
  small?: boolean;
  full?: boolean;
  textVariant?: string;
};

const SadBox = ({
  title,
  description,
  small = false,
  full = false,
  textVariant = "gray",
  ...props
}: Props & FlexProps): React.ReactElement => (
  <Flex align="center" justify="center" textAlign="center" {...props}>
    {!checkIfFullFridge() ?
      <Box>
        <Image
          w={small ? "200px" : { base : "200px", sm: "300px" }}
          mb="30px"
          mx="auto"
          alt=""
          src={full ? `${publicRuntimeConfig.basePath}/box.png` : `${publicRuntimeConfig.basePath}/empty.png`}
        />
        {title && (
          <Heading variant={textVariant} size={"md"} mb="15px" fontSize={{ base: "18px", sm: "20px" }}>
            {title}
          </Heading>
        )}
        {description && (
          <Heading variant={textVariant} size={"md"} mt="0" fontSize={{ base: "18px", sm: "20px" }}>
            {description}
          </Heading>
        )}
      </Box>
    :
      <Box>
        <Box position="relative" w={small ? { base: "80px", sm: "100px" } : { base: "100px", sm: "150px" }} mx="auto">
          <Image
            src={full ? `${publicRuntimeConfig.basePath}/fridgeOpen.png` : `${publicRuntimeConfig.basePath}/fridgeOpenEmpty.png`}
            alt=""
            d="block"
            mx="auto"
            w="100%"
          />
          <Image
            src={`${publicRuntimeConfig.basePath}/fridge2.png`}
            alt=""
            transform="translateX(-5px) perspective(1200px) rotateY(-65deg)"
            transformOrigin="left"
            position="absolute"
            top={0}
            left={0}
            bottom={0}
            right={0}
            h="100%"
          />
        </Box>
        {title && (
          <Heading variant={textVariant} size={"md"} mb="15px" fontSize={{ base: "18px", sm: "20px" }}>
            {title}
          </Heading>
        )}
        {description && (
          <Heading variant={textVariant} size={"md"} mt="0" fontSize={{ base: "18px", sm: "20px" }}>
            {description}
          </Heading>
        )}
      </Box>
    }
  </Flex>
);

export default SadBox;
